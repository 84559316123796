import { create } from 'zustand'
import { setRetorikEvent } from './utilsStore'
import { RetorikEvent, RecognitionState, DeviceType } from '../../models/enums'
import type {
  CustomVoice,
  PonyfillFactoryCredentials,
  SpeechRecognitionOptions
} from '../../models/types'
import type { Boundary } from '../../models/speechTypes'
import type { RetorikActivity } from '../../models/activityTypes'

interface SpeechStore {
  ponyfillCredentials: PonyfillFactoryCredentials | undefined
  useContinuousRecognition: boolean
  customVoice: CustomVoice | undefined
  voice: SpeechSynthesisVoice | null
  speaking: boolean
  muted: boolean
  cancel: boolean
  boundaryData: Array<Boundary>
  currentReplyToId: string | undefined
  currentPlaying: RetorikActivity | undefined
  endedActivities: Array<string>
  currentOrLastPlayedActivity: RetorikActivity | undefined
  // Speech recognition data
  activeRecognitionState: RecognitionState
  lastRecognitionInterim: string
  // Streaming by multiple events data
  streamingReplyToId: string | null
  streamingQueue: Array<RetorikActivity>
  streamingQueueForText: Array<RetorikActivity>
  currentStreaming: string | null
  streamingQueueFullLength: number
  streamingReplyToIdToIgnore: string | null
  // Speech recognition improvement words retrieved through activities
  speechRecognitionDynamicGrammars: Array<string>
}

const initialState: SpeechStore = {
  ponyfillCredentials: undefined,
  useContinuousRecognition: false,
  customVoice: undefined,
  voice: null,
  speaking: false,
  muted: false,
  cancel: false,
  boundaryData: [],
  currentReplyToId: undefined,
  currentPlaying: undefined,
  endedActivities: [],
  currentOrLastPlayedActivity: undefined,
  activeRecognitionState: RecognitionState.Closed,
  lastRecognitionInterim: '',
  streamingReplyToId: null,
  streamingQueue: [],
  streamingQueueForText: [],
  currentStreaming: null,
  streamingQueueFullLength: 0,
  streamingReplyToIdToIgnore: null,
  speechRecognitionDynamicGrammars: []
}

export const useSpeechStore = create<SpeechStore>()(() => {
  return initialState
})

export const setPonyfillFactoryCredentials = (
  value: PonyfillFactoryCredentials | undefined
): void => {
  useSpeechStore.setState({ ponyfillCredentials: value })
}

export const setUseContinuousRecognition = (
  deviceType: DeviceType,
  options?: SpeechRecognitionOptions
): void => {
  if (options?.enableContinuousRecognitionOnAllDevices) {
    useSpeechStore.setState({ useContinuousRecognition: true })
  } else if (options?.enableContinuousRecognitionOn) {
    switch (deviceType) {
      case DeviceType.mobile:
        useSpeechStore.setState({
          useContinuousRecognition:
            !!options.enableContinuousRecognitionOn.mobile
        })
        break
      case DeviceType.widget:
        useSpeechStore.setState({
          useContinuousRecognition:
            !!options.enableContinuousRecognitionOn.widget
        })
        break
      case DeviceType.landscape:
        useSpeechStore.setState({
          useContinuousRecognition:
            !!options.enableContinuousRecognitionOn.desktop
        })
        break
      case DeviceType.borne:
        useSpeechStore.setState({
          useContinuousRecognition:
            !!options.enableContinuousRecognitionOn.borne
        })
        break
      default:
        useSpeechStore.setState({ useContinuousRecognition: false })
        break
    }
  } else {
    useSpeechStore.setState({ useContinuousRecognition: false })
  }
}

export const setCustomVoice = (value: CustomVoice | undefined): void => {
  useSpeechStore.setState({ customVoice: value })
}

export const setVoice = (value: SpeechSynthesisVoice | null): void => {
  useSpeechStore.setState({ voice: value })
}

export const setSpeaking = (value: boolean): void => {
  useSpeechStore.setState({ speaking: value })
  setRetorikEvent(value ? RetorikEvent.SpeechStarted : RetorikEvent.SpeechEnded)
}

export const setMuted = (value: boolean): void => {
  useSpeechStore.setState({ muted: value })
}

export const setCancel = (cancel: boolean): void => {
  useSpeechStore.setState({ cancel: cancel })
}

export const setBoundaryData = (value: Array<Boundary>): void => {
  useSpeechStore.setState({ boundaryData: value })
}

export const setCurrentReplyToId = (value: string | undefined): void => {
  useSpeechStore.setState({ currentReplyToId: value })
}

export const setCurrentPlaying = (value: RetorikActivity | undefined): void => {
  useSpeechStore.setState({ currentPlaying: value })
  value && setCurrentOrLastPlayedActivity(value)
}

export const setEndedActivities = (value: Array<string>): void => {
  useSpeechStore.setState({ endedActivities: value })
}

export const setCurrentOrLastPlayedActivity = (
  value: RetorikActivity | undefined
): void => {
  useSpeechStore.setState({ currentOrLastPlayedActivity: value })
}

// Speech Recognition utils
export const setActiveRecognitionState = (value: RecognitionState): void => {
  useSpeechStore.setState({ activeRecognitionState: value })
}

export const setLastRecognitionInterim = (value: string): void => {
  useSpeechStore.setState({ lastRecognitionInterim: value })
}

export const setStreamingReplyToId = (value: string | null): void => {
  useSpeechStore.setState({
    streamingReplyToId: value
  })
}

export const setStreamingQueue = (value: Array<RetorikActivity>): void => {
  useSpeechStore.setState({
    streamingQueue: value,
    streamingQueueForText: value
  })
}

export const addToStreamingQueue = (value: RetorikActivity): void => {
  const idToIgnore = useSpeechStore.getState().streamingReplyToIdToIgnore
  if (value.replyToId && value.replyToId !== idToIgnore) {
    // Set replyToId and new queue if replyToId is different from the current one
    if (useSpeechStore.getState().streamingReplyToId !== value.replyToId) {
      useSpeechStore.setState({
        streamingQueueFullLength: 0,
        streamingReplyToId: value.replyToId,
        streamingQueue: [value],
        streamingQueueForText: [value]
      })
    } else {
      useSpeechStore.setState({
        streamingQueue: [...useSpeechStore.getState().streamingQueue, value],
        streamingQueueForText: [
          ...useSpeechStore.getState().streamingQueueForText,
          value
        ]
      })
    }
  }
}

/**
 * Remove the first activity in the streaming queue.
 */
export const removeFirstFromStreamingQueue = (): void => {
  const currentQueue = [...useSpeechStore.getState().streamingQueue]
  if (currentQueue.length) {
    currentQueue.splice(0, 1)
    useSpeechStore.setState({
      streamingQueue: [...currentQueue]
    })
  }
}

/**
 * Check if the replyToId is the same as the current one. If not, kill the current streaming.
 * @param {string} replyToId
 */
export const checkActivityReplyToId = (replyToId?: string): void => {
  const currentId = useSpeechStore.getState().streamingReplyToId
  currentId !== replyToId && killCurrentStreaming()
}

/**
 * Stop current streaming and prevent the processing of future data received for this stream.
 */
export const killCurrentStreaming = (): void => {
  const currentId = useSpeechStore.getState().streamingReplyToId
  useSpeechStore.setState({
    streamingReplyToId: null,
    streamingQueue: [],
    streamingQueueForText: [],
    currentStreaming: null,
    streamingQueueFullLength: 0,
    streamingReplyToIdToIgnore: currentId
  })
}

export const setCurrentStreaming = (value: string | null): void => {
  useSpeechStore.setState({ currentStreaming: value })
}

/**
 * Set the amount of streaming activities creating the full text to display.
 * @param {number} value amount of activities
 * @param {string | undefined} replyToId replyToId related to the stream
 */
export const setStreamingQueueFullLength = (
  value: number,
  replyToId?: string
): void => {
  if (replyToId) {
    const currentStreamingId = useSpeechStore.getState().streamingReplyToId
    replyToId === currentStreamingId &&
      useSpeechStore.setState({ streamingQueueFullLength: value })
  } else {
    useSpeechStore.setState({ streamingQueueFullLength: value })
  }
}

export const addSpeechRecognitionDynamicGrammar = (
  value: string | Array<string>
): void => {
  const currentGrammars =
    useSpeechStore.getState().speechRecognitionDynamicGrammars

  // For each value, verifiy if it doesn't exist in the array yet
  if (Array.isArray(value)) {
    const valuesToAdd: Array<string> = []
    value.forEach((val) => {
      !currentGrammars.includes(val) && valuesToAdd.push(val)
    })

    valuesToAdd.length &&
      useSpeechStore.setState({
        speechRecognitionDynamicGrammars: [...currentGrammars, ...valuesToAdd]
      })
  } else {
    !currentGrammars.includes(value) &&
      useSpeechStore.setState({
        speechRecognitionDynamicGrammars: [...currentGrammars, value]
      })
  }
}

export const toggleMicrophone = (): void => {
  document.dispatchEvent(
    new CustomEvent('retorikStartListening', { detail: undefined })
  )
}

/**
 * Reset all data within the speech store
 */
export const resetSpeechStore = (): void => {
  useSpeechStore.setState({ ...initialState })
}
