import React, { useEffect, useRef } from 'react'
import { useSpeechStore } from '../Contexts/speechStore'
import { useRetorikStore } from '../Contexts/retorikStore'
import { useUtilsStore } from '../Contexts/utilsStore'
import { useViewStore } from '../Contexts/viewStore'
import { sendTyping } from '../Contexts/directLineStore'
import { CurrentSubView, RetorikEvent, Routes } from '../../models/enums'

interface SendTypingIndicatorProps {
  isRetorikNews: boolean
}

const typingTimer = 2000

const SendTypingIndicator = ({
  isRetorikNews
}: SendTypingIndicatorProps): JSX.Element => {
  const speaking = useSpeechStore((state) => state.speaking)
  const streamingQueue = useSpeechStore((state) => state.streamingQueue)
  const streamingQueueFullLength = useSpeechStore(
    (state) => state.streamingQueueFullLength
  )
  const lastRecognitionInterim = useSpeechStore(
    (state) => state.lastRecognitionInterim
  )
  const route = useViewStore((state) => state.route)
  const loaderClosed = useRetorikStore((state) => state.loaderClosed)
  const appAvailable = useRetorikStore((state) => state.appAvailable)
  const isWaitingForResponse = useRetorikStore(
    (state) => state.isWaitingForResponse
  )
  const currentSubView = useUtilsStore((state) => state.currentSubView)
  const listOpened = useUtilsStore((state) => state.listOpened)
  const retorikEvent = useUtilsStore((state) => state.retorikEvent)
  const userIsTyping = useUtilsStore((state) => state.userIsTyping)
  const userIsSwiping = useUtilsStore((state) => state.userIsSwiping)

  const dataRef = useRef<boolean | null>(false)

  useEffect(() => {
    dataRef.current =
      !isWaitingForResponse &&
      // Don't send typing activity when we are receiving streaming events
      !(streamingQueue.length && streamingQueueFullLength === 0) &&
      (!loaderClosed ||
        lastRecognitionInterim !== '' ||
        userIsTyping ||
        userIsSwiping ||
        speaking ||
        currentSubView !== CurrentSubView.none ||
        route === Routes.News ||
        retorikEvent === RetorikEvent.DetailViewOpen ||
        listOpened)
  }, [
    appAvailable,
    loaderClosed,
    lastRecognitionInterim,
    userIsTyping,
    userIsSwiping,
    speaking,
    streamingQueue,
    streamingQueueFullLength,
    currentSubView,
    route,
    retorikEvent,
    listOpened,
    isWaitingForResponse
  ])

  const checkShouldSendTypingIndicator = (): void => {
    dataRef.current && !isRetorikNews && sendTyping()
  }

  useEffect(() => {
    const interval = setInterval(() => {
      checkShouldSendTypingIndicator()
    }, typingTimer)

    return () => {
      interval && clearInterval(interval)
    }
  }, [])

  return <React.Fragment />
}

export default SendTypingIndicator
