{
  "name": "@davi-ai/retorik-framework",
  "version": "1.1.8-preview.20241202.3",
  "description": "Retorik Framework package",
  "author": "DAVI",
  "license": "MIT",
  "repository": "none",
  "main": "dist/index.js",
  "module": "dist/index.modern.js",
  "types": "dist/index.d.ts",
  "source": "src/index.tsx",
  "standalone": "standalone/index.js",
  "targets": {
    "standalone": {
      "context": "browser",
      "outputFormat": "esmodule",
      "distDir": "standalone",
      "includeNodeModules": {
        "@storybook/addon-actions": false,
        "@storybook/addon-essentials": false,
        "@storybook/addon-interactions": false,
        "@storybook/addon-links": false,
        "@storybook/addon-postcss": false,
        "@storybook/builder-webpack5": false,
        "@storybook/manager-webpack5": false,
        "@storybook/preset-create-react-app": false,
        "@storybook/react": false,
        "@storybook/testing-library": false,
        "@testing-library/jest-dom": false,
        "@testing-library/react": false,
        "@testing-library/react-hooks": false,
        "@testing-library/user-event": false,
        "eslint": false,
        "eslint-config-prettier": false,
        "eslint-config-standard": false,
        "eslint-config-standard-react": false,
        "eslint-plugin-import": false,
        "eslint-plugin-node": false,
        "eslint-plugin-prettier": false,
        "eslint-plugin-promise": false,
        "eslint-plugin-react": false,
        "eslint-plugin-standard": false,
        "jest-junit": false,
        "npm-run-all": false,
        "parcel": false,
        "prettier": false,
        "raw-loader": false,
        "react-docgen-typescript-plugin": false,
        "react-scripts": false,
        "react-test-renderer": false,
        "tailwindcss": false,
        "typescript": false
      }
    },
    "module": {
      "context": "browser",
      "outputFormat": "esmodule",
      "distDir": "dist"
    }
  },
  "engines": {
    "node": ">=10"
  },
  "scripts": {
    "build": "parcel build",
    "start": "parcel watch",
    "test": "jest --env=jsdom --reporters=jest-junit",
    "test-update-snapshots": "jest --env=jsdom --updateSnapshot",
    "test-coverage": "jest --env=jsdom --reporters=jest-junit --coverage --coverageReporters=cobertura",
    "report": "npx parcel build src/index.tsx --reporter @parcel/reporter-bundle-analyzer",
    "credentials": "vsts-npm-auth -config .npmrc",
    "storybook": "npm run build-tailwind && start-storybook -p 6006",
    "build-storybook": "npm run build-tailwind && build-storybook",
    "build-tailwind": "npx tailwindcss build -i ./src/tailwind.css -o ./.storybook/stories/styles.css"
  },
  "peerDependencies": {
    "react": "18",
    "react-dom": "18"
  },
  "devDependencies": {
    "@babel/core": "^7.19.1",
    "@babel/plugin-proposal-decorators": "^7.24.7",
    "@babel/plugin-proposal-private-property-in-object": "^7.21.11",
    "@babel/preset-env": "^7.25.4",
    "@babel/preset-react": "^7.24.7",
    "@babel/preset-typescript": "^7.24.7",
    "@babel/runtime": "^7.23.8",
    "@parcel/packager-ts": "^2.12.0",
    "@parcel/reporter-bundle-analyzer": "^2.12.0",
    "@parcel/reporter-sourcemap-visualiser": "^2.12.0",
    "@parcel/transformer-inline-string": "^2.12.0",
    "@parcel/transformer-typescript-types": "^2.12.0",
    "@parcel/validator-eslint": "^2.12.0",
    "@parcel/validator-typescript": "^2.12.0",
    "@storybook/addon-actions": "^6.5.12",
    "@storybook/addon-essentials": "^6.5.12",
    "@storybook/addon-interactions": "^6.5.12",
    "@storybook/addon-links": "^6.5.12",
    "@storybook/addon-postcss": "^2.0.0",
    "@storybook/builder-webpack5": "^6.5.12",
    "@storybook/manager-webpack5": "^6.5.12",
    "@storybook/preset-create-react-app": "^4.1.2",
    "@storybook/react": "^6.5.12",
    "@storybook/testing-library": "^0.0.13",
    "@testing-library/jest-dom": "6.5.0",
    "@testing-library/react": "16.0.1",
    "@testing-library/react-hooks": "8.0.1",
    "@testing-library/user-event": "14.5.2",
    "@types/jest": "^27.5.1",
    "@types/leaflet": "^1.8.0",
    "@types/node": "^12.12.38",
    "@types/react": "18",
    "@types/react-dom": "18",
    "@types/testing-library__jest-dom": "^5.14.5",
    "@types/testing-library__react": "latest",
    "@typescript-eslint/eslint-plugin": "^5.0",
    "@typescript-eslint/parser": "^5.0",
    "autoprefixer": "^10.4.7",
    "babel-eslint": "^10.0.3",
    "babel-jest": "^29.7.0",
    "babel-loader": "^8.2.5",
    "buffer": "^5.7.1",
    "canvas": "^2.9.0",
    "cross-env": "^7.0.2",
    "crypto-browserify": "^3.12.0",
    "eslint": "^7.32.0",
    "eslint-config-prettier": "^6.7.0",
    "eslint-config-standard": "^14.1.0",
    "eslint-config-standard-react": "^9.2.0",
    "eslint-plugin-import": "^2.18.2",
    "eslint-plugin-node": "^11.0.0",
    "eslint-plugin-prettier": "^3.1.1",
    "eslint-plugin-promise": "^4.2.1",
    "eslint-plugin-react": "^7.17.0",
    "eslint-plugin-standard": "^4.0.1",
    "https-browserify": "^1.0.0",
    "jest": "^29.7.0",
    "jest-environment-jsdom": "^29.7.0",
    "jest-junit": "16.0.0",
    "npm-run-all": "^4.1.5",
    "parcel": "^2.12.0",
    "postcss": "^8.4.14",
    "postcss-url": "^10.1.3",
    "prettier": "^2.0.4",
    "process": "^0.11.10",
    "punycode": "^1.4.1",
    "querystring-es3": "^0.2.1",
    "raw-loader": "^4.0.2",
    "react": "^18",
    "react-docgen-typescript-plugin": "^1.0.5",
    "react-dom": "^18",
    "react-scripts": "5.0.1",
    "react-test-renderer": "^18.3.1",
    "stream-browserify": "^3.0.0",
    "stream-http": "^3.2.0",
    "string_decoder": "^1.3.0",
    "tailwindcss": "^3.1.0",
    "typescript": "5.0",
    "url": "^0.11.0",
    "util": "^0.12.4"
  },
  "files": [
    "dist"
  ],
  "dependencies": {
    "@davi-ai/body-engine-sprite": "1.0.3",
    "@davi-ai/retorik-weather": "1.0.0",
    "@davi-ai/speechmarkdown-davi-js": "1.0.6",
    "@davi-ai/web-speech-cognitive-services-davi": "2.0.8",
    "@fluentui/react": "^8.103.3",
    "@lottiefiles/react-lottie-player": "^3.4.1",
    "@opentelemetry/api": "^1.4.1",
    "@opentelemetry/exporter-trace-otlp-http": "^0.41.0",
    "@opentelemetry/resources": "^1.15.0",
    "@opentelemetry/sdk-trace-web": "^1.15.0",
    "@opentelemetry/semantic-conventions": "^1.15.0",
    "@react-spring/types": "^9.7.3",
    "@react-spring/web": "^9.7.3",
    "@swc/helpers": "^0.5.0",
    "@types/geojson": "^7946.0.10",
    "@types/jsdom": "^16.2.14",
    "@use-gesture/react": "^10.2.15",
    "adaptivecards": "3.0.1",
    "botframework-directlinejs": "^0.15.5",
    "classnames": "^2.3.1",
    "leaflet": "^1.9.2",
    "markdown-it": "14.1.0",
    "pdfjs-dist": "3.11.174",
    "postcss-copy": "^7.1.0",
    "qrcode": "^1.5.1",
    "react-device-detect": "^2.2.3",
    "react-error-boundary": "^4.0.10",
    "react-leaflet": "^4.1.0",
    "react-pdf": "7.7.1",
    "react-shadow": "^19.0.3",
    "react-webcam": "^6.0.0",
    "swiper": "^8.4.5",
    "zustand": "^4.1.1"
  }
}
