import React from 'react'
import { setModalFullscreenContent } from '../Contexts/utilsStore'

import {
  CONTENT_TYPE_NEWS,
  CONTENT_TYPE_POI,
  CONTENT_TYPE_EMPTY,
  CONTENT_TYPE_IMAGE,
  CONTENT_TYPE_LINK,
  CONTENT_TYPE_SUGGESTEDACTION,
  CONTENT_TYPE_DISCOVER,
  CONTENT_TYPE_SUGGESTION,
  CONTENT_TYPE_GRID,
  CONTENT_TYPE_TILE,
  CONTENT_TYPE_MODAL_EXTERNALURI
} from '../../models/attachmentTypes'
import type { DirectLineAttachment } from '../../models/directLine'

import NewsAttachment from '../Attachments/NewsAttachment'
import POIAttachment from '../Attachments/POIAttachment'
import EmptyAttachment from '../Attachments/EmptyAttachment'
import ImageAttachment from '../Attachments/ImageAttachment'
import LinkAttachment from '../Attachments/LinkAttachment'
import MultiLinkAttachment from '../Attachments/MultiLinkAttachment'
import SuggestedActionAttachment from '../Attachments/SuggestedActionAttachment'
import DiscoverAttachment from '../Attachments/DiscoverAttachment'
import SuggestionAttachment from '../Attachments/SuggestionAttachment'
import GridAttachment from '../Attachments/GridAttachment'
import AdaptiveCardAttachment from '../Attachments/AdaptiveCardAttachment'
import IFrame from '../Utils/IFrame'

interface AttachmentRendererProps {
  attachment: DirectLineAttachment
  history?: boolean
  height?: number
}

const renderAttachment = ({
  attachment,
  history,
  height
}: AttachmentRendererProps) => {
  switch (attachment.contentType) {
    case CONTENT_TYPE_NEWS:
      return <NewsAttachment {...{ ...attachment.content, height: height }} />
    case CONTENT_TYPE_POI:
      return <POIAttachment {...attachment.content} />
    case CONTENT_TYPE_EMPTY:
      return <EmptyAttachment />
    case CONTENT_TYPE_IMAGE:
      return <ImageAttachment {...{ ...attachment.content, height: height }} />
    case CONTENT_TYPE_LINK:
      return Array.isArray(attachment.content.urlData) ? (
        <MultiLinkAttachment {...{ ...attachment.content, height: height }} />
      ) : (
        <LinkAttachment {...{ ...attachment.content, height: height }} />
      )
    case CONTENT_TYPE_SUGGESTEDACTION:
      return (
        <SuggestedActionAttachment
          {...attachment.content}
          history={history}
          height={height}
        />
      )
    case CONTENT_TYPE_DISCOVER:
      return (
        <DiscoverAttachment
          {...attachment.content}
          history={history}
          height={height}
        />
      )
    case CONTENT_TYPE_SUGGESTION:
      return (
        <SuggestionAttachment
          {...attachment.content}
          history={history}
          height={height}
        />
      )

    case CONTENT_TYPE_GRID:
    case CONTENT_TYPE_TILE:
      return <GridAttachment {...attachment.content} history={history} />

    case CONTENT_TYPE_MODAL_EXTERNALURI: {
      if (!history) {
        const customComponent = <IFrame {...attachment.content} />
        setTimeout(() => {
          setModalFullscreenContent(customComponent)
        }, 250)
      }

      return <React.Fragment />
    }

    default: {
      return (
        <AdaptiveCardAttachment content={attachment.content} height={height} />
      )
    }
  }
}

export default renderAttachment
