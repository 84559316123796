import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  setStreamingQueueFullLength,
  useSpeechStore
} from '../../Contexts/speechStore'
import { useLocaleStore } from '../../Contexts/localeStore'
import { useRetorikStore } from '../../Contexts/retorikStore'
import { RetorikActivity } from '../../../models/activityTypes'
import { processMarkdownAndSpeechMarkdown } from '../../../utils/markdownUtils'
import Attachments from '../../Attachments/Attachments'

interface DisplayStreamingProps {
  triggerScroll: () => void
  setStreamingEnded: (value: boolean) => void
  streamingActivity?: RetorikActivity
  currentStreaming: string | null
  isMobile?: boolean
}

const interval = 150 // 20 // 25

const DisplayStreaming = ({
  triggerScroll,
  setStreamingEnded,
  streamingActivity,
  currentStreaming,
  isMobile
}: DisplayStreamingProps): JSX.Element => {
  const locale = useLocaleStore((state) => state.locale)
  const streamingQueue = useSpeechStore((state) => state.streamingQueueForText)
  const streamingCount = useSpeechStore(
    (state) => state.streamingQueueFullLength
  )
  const forceMobileView = useRetorikStore(
    (state) => state.configuration.forceMobileView
  )

  const [streamingTextToDisplay, setStreamingTextToDisplay] =
    useState<string>('')
  const [currentTextEnded, setCurrentTextEnded] = useState<boolean>(true)
  const [currentQueueIndex, setCurrentQueueIndex] = useState<number>(0)

  const time = useMemo<Date | null>(() => {
    if (streamingQueue.length) {
      const answerTime = streamingQueue[0].localTimestamp
        ? new Date(streamingQueue[0].localTimestamp)
        : streamingQueue[0].timestamp
        ? new Date(streamingQueue[0].timestamp)
        : new Date()

      return answerTime
    }

    return null
  }, [streamingQueue])

  const intervalRef = useRef<NodeJS.Timer | null>(null)
  const streamRef = useRef<Array<string>>([])
  const streamIndexRef = useRef<number>(0)
  const previousTextRef = useRef<Array<string>>([])

  const handleNewStreaming = (text: string): void => {
    text = processMarkdownAndSpeechMarkdown(text, false).htmlText

    streamRef.current = text.split(' ')
    setCurrentTextEnded(false)

    intervalRef.current = setInterval(() => {
      if (streamIndexRef.current < streamRef.current.length - 1) {
        const current = streamRef.current[streamIndexRef.current]
        setStreamingTextToDisplay((value) => `${value} ${current}`)
        streamIndexRef.current = streamIndexRef.current + 1
        streamIndexRef.current % 10 === 0 && triggerScroll()
      } else {
        setCurrentTextEnded(true)
        triggerScroll()
        intervalRef.current && clearInterval(intervalRef.current)
      }
    }, interval)
  }

  const resetData = (): void => {
    setCurrentQueueIndex(0)
    streamIndexRef.current = 0
    previousTextRef.current = []
    setStreamingQueueFullLength(0)
    intervalRef?.current && clearInterval(intervalRef.current)
  }

  useEffect(() => {
    if (currentTextEnded && streamingQueue?.length) {
      if (streamingCount === 0 || currentQueueIndex < streamingCount) {
        if (streamingQueue.length > currentQueueIndex) {
          setStreamingEnded(false)
          let text = ''
          for (let i = 0; i <= currentQueueIndex; i++) {
            text = text + streamingQueue[i].text
          }

          handleNewStreaming(text)
          setCurrentQueueIndex((value) => value + 1)
        }
      } else {
        let fullText = ''
        streamingQueue.forEach((activity) => {
          fullText = `${fullText} ${activity.text}`
        })
        setStreamingTextToDisplay(
          processMarkdownAndSpeechMarkdown(fullText, false).htmlText
        )
        setStreamingEnded(true)
        resetData()
      }
    }
  }, [streamingQueue, currentTextEnded, streamingCount])

  useEffect(() => {
    if (!currentStreaming) {
      setCurrentTextEnded(true)
      setStreamingTextToDisplay('')
      resetData()
    }
  }, [currentStreaming])

  useEffect(() => {
    return () => {
      intervalRef?.current && clearInterval(intervalRef.current)
    }
  }, [])

  return (
    <div className='rf-text-textModePanelConversationBot rf-flex rf-flex-col rf-items-start rf-gap-2'>
      {/* Time */}
      {isMobile && time && (
        <div className='rf-px-4 rf-text-[#9A9A9A]'>
          {time.toLocaleString(locale, {
            hour: 'numeric',
            minute: 'numeric'
          })}
        </div>
      )}

      {streamingTextToDisplay && (
        <div
          className='rf-px-4'
          dangerouslySetInnerHTML={{ __html: streamingTextToDisplay }}
        />
      )}

      {currentQueueIndex === 0 ? (
        <React.Fragment>
          {/* Attachments */}
          {!!streamingActivity?.attachments?.length && (
            <div
              className={`${forceMobileView ? 'rf-m-auto' : 'rf-w-full'} ${
                streamingActivity.attachments.length <= 1 && 'rf-px-4'
              }`}
            >
              <Attachments activity={streamingActivity} />
            </div>
          )}
        </React.Fragment>
      ) : (
        <div className='rf-px-4 rf-font-bold rf-animate-blink'>...</div>
      )}
    </div>
  )
}

export default DisplayStreaming
