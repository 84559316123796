import coreRenderAgent from './renderAgent'
import coreRenderWidget from './renderWidget'
import { RetorikAgent, RetorikWidget, RetorikNews } from './components'
import { characters } from './utils/defaultCharacters'
import fetchAvailableLanguages from './utils/fetchAvailableLanguages'
import getRetorikConfigs from './utils/getConfigs'
import getThemeColors from './utils/getThemeColors'
import {
  useLocaleStore,
  getLocale,
  setLocale,
  resetLocaleStore
} from './components/Contexts/localeStore'
import {
  openWidget,
  closeWidget,
  toggleWidget,
  toggleDisplay
} from './components/Contexts/widgetStore'
import {
  useUtilsStore,
  setCurrentCustomView,
  setCurrentSubView,
  showAgent,
  hideAgent,
  sendActivity,
  addDetailedAttachmentTrigger,
  setModalFullscreenContent,
  setPrintingCallback,
  setDashboardOpened,
  resetUtilsStore
} from './components/Contexts/utilsStore'
import {
  setDisplaySubtitles,
  setAgentData,
  setMode,
  setLoaderClosed,
  useRetorikStore,
  resetRetorikStore
} from './components/Contexts/retorikStore'
import {
  toggleMicrophone,
  useSpeechStore,
  resetSpeechStore
} from './components/Contexts/speechStore'
import { resetActivityStore } from './components/Contexts/activityStore'
import {
  sendMessage,
  sendEvent,
  resetDirectlineStore
} from './components/Contexts/directLineStore'
import { resetAdaptiveCardStore } from './components/Contexts/adaptiveCardStore'

const resetStores = () => {
  resetLocaleStore()
  resetUtilsStore()
  resetRetorikStore()
  resetSpeechStore()
  resetActivityStore()
  resetDirectlineStore()
  resetAdaptiveCardStore()
}

const renderAgent = coreRenderAgent.bind(null, RetorikAgent)
const renderWidget = coreRenderWidget.bind(null, RetorikWidget)

export default RetorikAgent

export {
  RetorikAgent,
  RetorikWidget,
  RetorikNews,
  renderAgent,
  characters,
  useRetorikStore,
  useUtilsStore,
  useLocaleStore,
  useSpeechStore,
  setCurrentCustomView,
  setCurrentSubView,
  sendActivity,
  sendMessage,
  sendEvent,
  fetchAvailableLanguages,
  getRetorikConfigs,
  getThemeColors,
  getLocale,
  setLocale,
  openWidget,
  closeWidget,
  toggleWidget,
  toggleDisplay,
  showAgent,
  hideAgent,
  addDetailedAttachmentTrigger,
  setMode,
  setLoaderClosed,
  setModalFullscreenContent,
  setPrintingCallback,
  setDashboardOpened,
  toggleMicrophone,
  resetStores
}

export type {
  AvailableLanguages,
  Configuration,
  ViewsConfiguration,
  View,
  BackgroundOptions,
  ChatbotData,
  AgentData,
  PonyfillFactoryCredentials,
  AddressData,
  UserData,
  Queue,
  CustomVoice,
  NewsConfig,
  WidgetConfiguration,
  CustomMenu,
  RetorikMenusConfiguration
} from './models/types'

export type {
  RetorikMainComponentProps,
  RetorikMainComponentConfiguration,
  RetorikSharedProps
} from './models/retorikTypes'

export type { POIContentType } from './models/attachmentTypes'

export type { RetorikThemeColors } from './models/colorTypes'

export {
  CONTENT_TYPE_POI,
  CONTENT_TYPE_NEWS,
  CONTENT_TYPE_ADAPTIVE_CARD
} from './models/attachmentTypes'

export {
  AvailableViews,
  AvailableSubViews,
  RetorikEvent,
  CurrentSubView,
  DeviceType,
  Emotions
} from './models/enums'

window.Retorik = {
  ...window.Retorik,
  renderAgent,
  renderWidget,
  showAgent,
  hideAgent,
  setLocale,
  sendMessage,
  sendEvent,
  sendActivity,
  displaySubtitles: setDisplaySubtitles,
  changeAgent: setAgentData,
  toggleMicrophone
}
