import React, { useMemo } from 'react'
import { useRetorikStore } from '../Contexts/retorikStore'
import { useSpeechStore } from '../Contexts/speechStore'
import { useUtilsStore, setListClosed } from '../Contexts/utilsStore'
import { useViewStore } from '../Contexts/viewStore'

import type { RetorikActivity } from '../../models/activityTypes'
import { Displays } from '../../models/enums'

import ListTemplate from '../Templates/List'
import Attachments from '../Attachments/Attachments'

const BotResponse = (): JSX.Element => {
  const showHomeAttachments = useViewStore((state) => state.showHomeAttachments)
  const speaking = useSpeechStore((state) => state.speaking)
  const lastRecognitionInterim = useSpeechStore(
    (state) => state.lastRecognitionInterim
  )
  const currentOrLastPlayedActivity = useSpeechStore(
    (state) => state.currentOrLastPlayedActivity
  )
  const displaySubtitles = useRetorikStore((state) => state.displaySubtitles)
  const listClosed = useUtilsStore((state) => state.listClosed)

  const rowSpanVertical = useMemo<string>(() => {
    return displaySubtitles && speaking
      ? 'rf-row-span-5 small-mobile:rf-row-span-6'
      : 'rf-row-span-6 small-mobile:rf-row-span-7'
  }, [displaySubtitles, speaking])

  const currentActivity = useMemo<RetorikActivity | undefined>(() => {
    return showHomeAttachments || currentOrLastPlayedActivity
  }, [currentOrLastPlayedActivity, showHomeAttachments])

  const currentDisplay = useMemo<Displays>(() => {
    if (currentActivity) {
      if (currentActivity.attachmentLayout) {
        switch (currentActivity.attachmentLayout.toLowerCase()) {
          case 'davilist':
            return Displays.list
          case 'carousel':
            return currentActivity.attachments &&
              currentActivity.attachments.length > 1
              ? Displays.carousel
              : Displays.normal
          case 'grid':
            return Displays.grid
          default:
            return Displays.none
        }
      }

      // If we have no attachment layout but attachment(s), we use list display by default is there are several attachments
      if (currentActivity.attachments?.length) {
        return currentActivity.attachments?.length > 1
          ? Displays.list
          : Displays.normal
      }
    }

    return Displays.none
  }, [currentActivity])

  return currentDisplay === Displays.none ? (
    <React.Fragment />
  ) : currentDisplay === Displays.list ? (
    lastRecognitionInterim === '' && !listClosed ? (
      <ListTemplate
        activity={currentActivity}
        onClose={(): void => setListClosed(true)}
      />
    ) : (
      <React.Fragment />
    )
  ) : (
    <div
      id='retorik-botResponse'
      className={`rf-relative rf-w-full rf-h-full
        rf-col-start-1 large-vertical:rf-col-start-1 large:rf-col-start-5 rf-col-span-full
        rf-row-start-6 small-mobile:rf-row-start-5 large:rf-row-start-5 large-vertical:rf-row-start-7 ${rowSpanVertical} large:rf-row-span-full large-vertical:rf-row-span-4
        rf-overflow-y-scroll rf-scrollbar-thin
      `}
    >
      {currentActivity && (
        <div className='rf-w-full rf-h-full rf-flex rf-flex-col rf-items-center rf-gap-2'>
          <Attachments activity={currentActivity} />
        </div>
      )}
    </div>
  )
}

export default BotResponse
