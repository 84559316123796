import React, { useMemo } from 'react'
import { useRetorikStore } from '../../Contexts/retorikStore'
import { Mode } from '../../../models/enums'
import renderAttachment from '../../Middlewares/attachmentMiddleware'
import Card from '../../AnswerPanel/Card'
import { DirectLineAttachment } from '../../../models/directLine'

interface SingleCardContainerProps {
  attachment: DirectLineAttachment
  history?: boolean
}

const classNameText = 'rf-max-h-full'
const classNameVocal =
  'rf-w-11/12 large:rf-w-3/5 large-vertical:rf-w-4/9 rf-min-w-11/12 large:rf-min-w-3/5 large-vertical:rf-min-w-4/9 rf-max-h-full'

const SingleCardContainer = ({
  attachment,
  history
}: SingleCardContainerProps): JSX.Element => {
  const mode = useRetorikStore((state) => state.mode)

  const className = useMemo<string>(() => {
    return mode === Mode.text ? classNameText : classNameVocal
  }, [mode])

  return attachment.contentType.includes('.card') ? (
    <Card className={history ? 'rf-w-full' : className}>
      {renderAttachment({
        attachment: attachment,
        history: history
      })}
    </Card>
  ) : (
    <React.Fragment>
      {renderAttachment({
        attachment: attachment,
        history: history
      })}
    </React.Fragment>
  )
}

export default SingleCardContainer
