import BackButton from './BackButton'
import Dashboard from './Dashboard'
import HistoryButton from './HistoryButton'
import LanguageButton from './LanguageButton'
import MicrophoneButton from './MicrophoneButton'
import Parameters from './Parameters'
import ParameterButton from './ParameterButton'
import TutorialButton from './TutorialButton'
import Version from './Version'

export {
  BackButton,
  Dashboard,
  HistoryButton,
  LanguageButton,
  MicrophoneButton,
  Parameters,
  ParameterButton,
  TutorialButton,
  Version
}
