interface ColorRGB {
  r: number
  g: number
  b: number
}

const getColorWithTransparency = (
  color: string,
  transparency: number
): string => {
  let tempColorObject: ColorRGB | null = null
  try {
    tempColorObject = color.includes('rgb')
      ? rgbToObject(color)
      : hexToRgb(color)
  } catch (error) {
    console.log(error)
  }

  if (tempColorObject) {
    return `rgba(${tempColorObject.r}, ${tempColorObject.g}, ${tempColorObject.b}, ${transparency})`
  }

  return color
}

const getColorForLinearGradient = (color: string): string => {
  let tempColorObject: ColorRGB | null = null
  try {
    tempColorObject = color.includes('rgb')
      ? rgbToObject(color)
      : hexToRgb(color)
  } catch (error) {
    console.log(error)
  }

  if (tempColorObject) {
    return `rgba(${tempColorObject.r / 2}, ${tempColorObject.g / 2}, ${
      tempColorObject.b / 2
    })`
  }

  return color
}

const rgbToObject = (rgb: string): ColorRGB => {
  // Replace unwanted characters
  rgb = rgb.replace('rgb(', '')
  rgb = rgb.replace(')', '')
  rgb = rgb.replace(' ', '')

  const stringArray = rgb.split(',')

  return {
    r: parseInt(stringArray[0], 16),
    g: parseInt(stringArray[1], 16),
    b: parseInt(stringArray[2], 16)
  }
}

const hexToRgb = (hex: string): ColorRGB => {
  // Remove '#' char if present
  if (hex.charAt(0) === '#') {
    hex = hex.slice(1)
  }

  // Transform if shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
  }

  return {
    r: parseInt(hex.slice(0, 2), 16),
    g: parseInt(hex.slice(2, 4), 16),
    b: parseInt(hex.slice(4), 16)
  }
}

export { getColorWithTransparency, getColorForLinearGradient, hexToRgb }
